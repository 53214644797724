import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useAppointmentList() {


  const doctor_Data = ref([]);
  const checklist_Data = ref([]);
  const patient_Data = ref([]);
  const patient_Total = ref(0);
  const searchQuery = ref('');
  const searchDoctorQuery = ref('');
  const searchDateQuery = ref('');
  const selected_status = ref(0);
  const refListTable = ref(null);
  const currentPage = ref(1);
  const pageLength = ref(10);
  const Total = ref(0);
  const dir = ref(false);
  const toast = useToast()
  const tableColumns = [

      { field: 'id', label: 'id', sortable: false, hidden:true },
        { field: 'patient_name', label: 'ชื่อ-สกุล', sortable: false },

        { field: 'doc_name', label: 'แพทย์', sortable: false },
        { field: 'date', label: 'วันที่นัดหมาย', sortable: false },
        { field: 'time', label: 'เวลานัดหมาย', sortable: false },
        
        { field: 'checklist_title', label: 'รายการตรวจ', sortable: false },

        { field: 'is_active', label: 'สถานะ', sortable: false },
        { field: 'action', label: 'การจัดการ', sortable: false },


    ]
    const Data = ref([]);
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;

    const refetchData = () => {
      refListTable.value.refresh()
  }
    watch([currentPage, pageLength, searchQuery,searchDoctorQuery,searchDateQuery,selected_status], () => {
      fetchData();
    })

    const fetchData = async () => {
        const offset = pageLength.value*(currentPage.value-1);
        Data.value  = [];
        await store
          .dispatch('app-appointment/fetchData', {
            limit: pageLength.value,
            offset:offset,
            keywords: searchQuery.value,
            doctor_keywords:searchDoctorQuery.value,
            is_active:selected_status.value,
            branch_id:branch_id,
            date:searchDateQuery.value,
            order_by:'appointment.id DESC'
         
          })
          .then(response => {


            Data.value = response.data.data;
            console.log('appointment appointment value',Data.value);
            const total = response.data.total?response.data.total: Data.value.length;
           
            Total.value = total
          })
          .catch((error) => {
            console.log('error',error);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching appointment' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }

    const fetchPatientData = () => {
        patient_Data.value = [];
        store
          .dispatch('app-appointment/fetchPatientData', {
            branch_id:branch_id,
            order_by:'patients.id DESC'
         
          })
          .then(response => {
            
            response.data.data.forEach((data) => data.full_name = data.first_name + data.last_name);

            patient_Data.value  = response.data.data
            const total = response.data.total?response.data.total: patient_Data.value.length;
           
            patient_Total.value = total
          })
          .catch((error) => {
            console.log('error',error);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching Patients' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }

    const fetchDoctorData  = async () => { 
      doctor_Data.value  = [];
      await store.dispatch('app-appointment/fetchDoctorData',{
        group_id:7, //doctor
        branch_id:branch_id,
        is_active:1,
      })
      .then(response => {
        response.data.data.forEach((data) => data.full_name = data.first_name + data.last_name);
        doctor_Data.value  = response.data.data
        console.log('doctor_Data value',doctor_Data.value);

      })
      .catch((error) => {
      console.log('error',error);
      })
  }
  
  const fetchChecklistData  = async () => { 
    checklist_Data.value  = [];
    await store.dispatch('app-appointment/fetchChecklistData',{
      branch_id:branch_id,
      is_active:1,
    })
    .then(response => {

    checklist_Data.value  = response.data.data
    console.log('appointment checklist_Data value',checklist_Data.value);

    })
    .catch((error) => {
    console.log('error',error);
    })
}
  
  fetchPatientData();
  fetchChecklistData();
  fetchDoctorData();
  fetchData();

  const deleteData = (id) => {
    //fetchDeleteProperty
    var data = {id:id}
    
    var res = store.dispatch('app-appointment/deleteData',data)
    res.then(response=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Delete Success",
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      fetchData();
    }).catch(()=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Error Delete",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })

    })
    //console.log('id', id);
    
  }
  const resolveUserStatusVariant = status => {
    
    if (status == 2) return 'warning'
    if (status == 1) return 'success'
    if (!status) return 'danger'
    return 'primary'
  }
  const resolveUserStatusnameVariant = status => {

      if (status == 2) return 'รอดำเนินการ'
      if (status == 1) return 'เสร็จสิ้น'
      if (!status) return 'ยกเลิก'
      return 'primary'
  }

    return {
        fetchData,
        tableColumns,
        fetchDoctorData,
        doctor_Data,
        fetchPatientData,
        patient_Data,
        fetchChecklistData,
        checklist_Data,
        deleteData,
        Total,
        Data,
        pageLength,
        dir,
        searchQuery,
        searchDoctorQuery,
        searchDateQuery,
        selected_status,
        currentPage,
        refListTable,
        resolveUserStatusVariant,
        resolveUserStatusnameVariant,
    }
}