<template>
    <b-sidebar
      id="appointment-form-sidebar"
      :visible="isAppointmentFormSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @shown="getData(AppointmentData)"
      @hidden="resetForm"
      @change="(val) => $emit('update:is-appointment-form-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{headertext}}
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
  
        </div>
  
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >


            <validation-provider
              #default="validationContext"
              name="title"
              rules="required"
            >
              <b-form-group
                label="ชื่อลูกค้า"
                label-for="title"
              >
              <v-select
                :options="patient_Data"
                v-model="Data.patient_id"
                :get-option-label="(option) => option.full_name"
                :reduce="val => val.id"
                >
                <template #option="{ full_name }">
                  {{ full_name }}
                  <br />
                </template>
              </v-select>
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            
            <validation-provider
              #default="validationContext"
              name="doctor"
              rules="required"
            >
              <b-form-group
                label="ชื่อแพทย์"
                label-for="doctor"

              >
              <v-select
                :options="doctor_Data"
                v-model="Data.user_id"
                :reduce="val => val.id"
                :get-option-label="(option) => option.full_name"
                item-value="id"
                >
                <template #option="{ full_name }">
                  {{ full_name }}
                  <br />
                </template>
              </v-select>
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            

            <validation-provider
            #default="validationContext"
            name="date"
            rules="required"
          >
            <b-form-group
              label="วันที่นัด"
              label-for="date"

            >
              <b-form-datepicker
                id="date"
                v-model="Data.date"
                type="date"
                :state="getValidationState(validationContext)"
                trim
                placeholder="วันที่นัด"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          
          <validation-provider
            #default="validationContext"
            name="time"
            rules="required"
          >
            <b-form-group
              label="เวลานัด"
              label-for="time"

            >
              <b-form-timepicker
                id="time"
                v-model="Data.time"
                type="time"
                :state="getValidationState(validationContext)"
                trim
                minutes-step="15"
                :hour12="false"
                placeholder="เวลานัด"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

            <validation-provider
              #default="validationContext"
              name="checklist"
            >
              <b-form-group
                label="รายการตรวจ"
                label-for="checklist"
              >
              <v-select
                :options="checklist_Data"
                v-model="Data.checklist_id"
                :get-option-label="(option) => option.title"
                :reduce="val => val.id"
                >
                <template #option="{ title }">
                  {{ title }}
                  <br />
                </template>
              </v-select>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>


            <validation-provider
              #default="validationContext"
              name="remark"
            >
              <b-form-group
                label="หมายเหตุ"
                label-for="remark"
              >
                <b-form-input
                  v-model="Data.remark"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="หมายเหตุ"
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          
            
            <!-- Username -->
            <validation-provider
              #default="validationContext"
              name="status"
            >
              <b-form-group
                label="status"
                label-for="status"
              >
                <b-form-radio-group
                  v-model="Data.is_active"
                  :options="options"
                  :state="getValidationState(validationContext)"
                >
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                </b-form-radio-group>
              </b-form-group>
            </validation-provider>
  
            
          
  
         
          
  
  
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
  
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </template>
  
  <script>
  import {
    BSidebar, BForm, BFormGroup, BFormRadioGroup, BFormCheckbox, BFormInput, BFormInvalidFeedback, BButton,BFormSelect,BFormDatepicker,BFormTimepicker,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref,onUnmounted } from '@vue/composition-api'
  import { required, alphaNum, email } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import countries from '@/@fake-db/data/other/countries'
  import store from '@/store'
  import useAppointmentList from './useAppointmentList'
  import { useToast } from 'vue-toastification/composition'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { slugify } from '@/@fake-db/utils'
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormRadioGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      BFormCheckbox,
      vSelect,
      BFormSelect,
      BFormDatepicker,
      BFormTimepicker,
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isAppointmentFormSidebarActive',
      event: 'update:is-appointment-form-sidebar-active',
    },
    props: {
      isAppointmentFormSidebarActive: {
        type: Boolean,
        required: true,
      },
     
      AppointmentData: {
        type: Object,
        required: true,
      },
      headertext:{
        type:String,
        required: true,
      }
  
    },
    data() {
      return {
        required,
        alphaNum,
        email,
        countries,
      }
    },
   
    setup(props, { emit }) {
      const options = ref([
          { html: '<b class="text-warning" >รอดำเนินการ</b>', value: 2 },
          { html: '<b class="text-success" >เสร็จสิ้น</b>', value: 1 },
          { html: '<b class="text-danger" >ยกเลิก</b>', value: 0 }
        ]);
      const toast = useToast();
      const userStorage = JSON.parse(localStorage.getItem('userData'));
      console.log(userStorage);
      const PATIENTS_STORE_MODULE_NAME = 'app-appointment';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, appointmentStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });
        const {
            patient_Data,
            doctor_Data,
            checklist_Data,
        } = useAppointmentList();
      const blankData = {
      
        unit: '',
        sub_unit: '',
        num: null,
        group_id: null,
        category_id: null,
        type_id: null,
        branch_id:userStorage.branch_id,
        is_active:0,
        created_by: null,
     
      }
  
      const Data = ref(JSON.parse(JSON.stringify(blankData)))
      const resetData = () => {
        Data.value = JSON.parse(JSON.stringify(blankData))
        
      }
      const getData = (data)=>{
        if(data){ 
          Data.value  = JSON.parse(JSON.stringify(data))
        }
      }
      
      const onSubmit = () => {
        //default 
        console.log(Data.value);
        Data.value.branch_id = userStorage.branch_id;
        Data.value.created_by = userStorage.id;
        //
        if(Data.value.is_active == true){
          Data.value.is_active = 1;
        }
        else if(Data.value.is_active == false){
          Data.value.is_active = 0;
        }
          if(Data.value.id){
            store.dispatch('app-appointment/updateData', Data.value)
            .then(() => {
              emit('refetch-data')
              emit('update:is-appointment-form-sidebar-active', false)
              toast({
              component: ToastificationContent,
              props: {
                title: "Save Success",
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            }).catch(()=>{
            toast({
              component: ToastificationContent,
              props: {
                title: "Error Save",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
      
          })
  
        }
        else{
            store.dispatch('app-appointment/addData', Data.value)
            .then(() => {
              emit('refetch-data')
              emit('update:is-appointment-form-sidebar-active', false)
              toast({
              component: ToastificationContent,
              props: {
                title: "Save Success",
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            }).catch(()=>{
            toast({
              component: ToastificationContent,
              props: {
                title: "Error Save",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
      
          })
  
          }   
      }
  
      const {
        refFormObserver,
        getValidationState,
        resetForm,
      } = formValidation(resetData)
  
  
      return {
        Data,
        onSubmit,
        
        options,
        refFormObserver,
        getValidationState,
        resetForm,
        getData,
        doctor_Data,
        patient_Data,
        checklist_Data
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  
  #appointment-form-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
  